import React, { useState } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Header from './ui/Header';
import theme from './ui/Theme';
import Home from '../components/Home';
// import Footer from './ui/Footer';

function App() {

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [value, setValue] = useState(0);


  return (
    <ThemeProvider
      theme={theme}>
      <BrowserRouter>
        <Header
          value={value}
          setValue={setValue}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
        />
        <Switch>
          <Route exact path="/" render={props => (
            <Home
              {...props}
              setValue={setValue}
              setSelectedIndex={setSelectedIndex}
            />
          )} />
          {/* <Route exact path="/location" component={() => <div>Location</div>} />
          <Route exact path="/room" component={() => <div>Room</div>} /> */}
        </Switch>
        {/* <Footer
          value={value}
          setValue={setValue}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
        /> */}
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
