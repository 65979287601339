import axios from 'axios';

const fecthCityData = async () => {
    const result = await axios.get("https://lalo.monument.health/api/City");
    return result;
};

const fecthLocationData = async () => {
    const result = await axios.get("https://lalo.monument.health/api/Location");
    return result;
};

const fecthRoomData = async () => {
    const result = await axios.get("https://lalo.monument.health/api/Room");
    return result;
};



const fecthData =
{
    fecthCityData,
    fecthLocationData,
    fecthRoomData
}

export default fecthData;