export const msalConfig = {
    auth: {
      clientId: "8bf273e2-a7b2-4936-bad7-ef6e85b66fba",
      authority: "https://login.microsoftonline.com/62828782-11ac-421a-b35a-7bc3e4c0bfbe",
      redirectUri: "https://lalo.monument.health/",//,"http://localhost:3000",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["User.Read"]
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  // export const graphConfig = {
  //     graphMeEndpoint: "https://graph.microsoft.com"
  // };