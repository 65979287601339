import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import Button from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    container: {
        height: 200,
        position: "relative",
        border: "3px",
    },
    button: {
        ...theme.typography.learnButton,
        fontSize: "0.9rem",
        height: 55,
        width: 200,
        backgroundColor: theme.palette.common.cream,
    },
}))

function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
    });
}

export const SignInButton = () => {
    const { instance } = useMsal();
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div >
                <Button variant="contained" className={classes.button} onClick={() => handleLogin(instance)}>Sign in here! </Button>
            </div>
        </div>
    );
}