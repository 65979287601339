import React, { useState, useEffect } from 'react';
import fetchData from "../api/fetchData";
import axios from 'axios';
import { makeStyles, useTheme } from '@material-ui/styles';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { useIsAuthenticated } from "@azure/msal-react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import { useMsal } from "@azure/msal-react";
import Divider from '@material-ui/core/Divider';
const useStyles = makeStyles(theme => ({
    mainContainer: {
        marginTop: "5em",
        [theme.breakpoints.down("md")]: {
            marginTop: "3em"
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: "2em"
        },

    },
    welcomeTextContainer: {
        minWidth: "21.5em",
        marginLeft: "1em",
        [theme.breakpoints.down("xs")]: {
            marginLeft: 0
        }
    },
    button: {
        ...theme.typography.learnButton,
        fontSize: "0.9rem",
        height: 35,
        width: 100,
        "&:hover": {
            backgroundColor: theme.palette.secondary.light
        }
    },
    cityCard: {
        position: "relative",
        borderRadius: 15,
        padding: "5em",

    }
}))

window.setTimeout(function () {
    window.location.reload();
}, 60000);

export default function Home(props) {
    const classes = useStyles();
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const [currRoom, setCurrRoom] = useState({})
    const [open, setOpen] = useState(false);
    const isAuthenticated = useIsAuthenticated();

    const [locations, setLocations] = useState([]);
    const [cities, setCities] = useState([]);
    const [room, setRoom] = useState([]);
    const { accounts } = useMsal();


    const userCookie = accounts[0] && accounts[0].username;

    const openRoom = (Room) => {
        // console.log("Hi", Room);
        return () => {
            // console.log("Hello", Room);
            setOpen(true);
            setCurrRoom(Room);
        }
    };

    function getTimeRemaining(endtime, currtime) {
        const total = Date.parse(endtime) - Date.parse(currtime);
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
        const days = Math.floor(total / (1000 * 60 * 60 * 24));
        const totmin = ('0' + minutes).slice(-2);// + ":" + ('0' + seconds).slice(-2);
        return {
            total,
            days,
            hours,
            totmin,
            seconds
        };
    };

    const disRoom = (True, loca, room, user, Name, taken) => {
        return () => {
            if (loca !== undefined && room !== undefined) {
                axios.post("https://lalo.monument.health/api/CheckInOut",
                    {

                        location: loca,
                        room: room,
                        user: user,

                    })
                    .then(function (res) {
                        // console.log("Response", res);
                        fetchData.fecthRoomData().then(res => {
                            setRoom(res.data);
                            //console.log("Room Data", res.data)        
                        });
                    })
                    .catch(err => {
                        console.error("ERROR", err);
                    });
            }
            setOpen(True);
        }
    };

    const unRoom = (ID) => {
        return () => {
            axios.post("https://lalo.monument.health/api/Release",
                {
                    cioid: ID,

                })
                .then(function (res) {
                    // console.log("Response", res);
                    fetchData.fecthRoomData().then(res => {
                        setRoom(res.data);
                        // console.log("Room Data", res.data)        
                    });
                })
                .catch(err => {
                    console.error("ERROR", err);
                });
        }
    };

    useEffect(() => {
        fetchData.fecthCityData().then(res => {
            setCities(res.data);
            //   console.log("City Data", res.data)
        });

        fetchData.fecthLocationData().then(res => {
            setLocations(res.data);
            // console.log("Location Data", res.data)        
        });

        fetchData.fecthRoomData().then(res => {
            setRoom(res.data);
            //console.log("Room Data", res.data)
        });

    }, []);


    return (
        <Grid container direction="column" className={classes.mainContainer}>
            <Grid item>
                <Grid container justify="flex-end" direction="column" alignItems="center">
                    <Grid sm item className={classes.welcomeTextContainer}>
                        <Typography align="center" variant="h2">
                            Welcome to the Lactation Room
                            <br />
                            Location Manager
                        </Typography>
                    </Grid>

                    {room.filter((room) => userCookie === room.UserID).map((room) => (

                        <div key={room.RoomID} style={{ display: (room.IsOpen === false ? "block" : "none") }}>
                            <Grid item style={{ marginTop: matchesSM ? "1em" : "3em" }}>
                                <Typography align="center" variant="h5" gutterBottom>
                                    Your Room
                                </Typography>
                            </Grid>

                            <Grid item style={{ width: "25em" }}>

                                <Paper>
                                    <Typography align="center" variant="subtitle1" gutterBottom>
                                        This room will be
                                        held until: <br /> <b>{room.ReleaseDateTime}</b>

                                    </Typography>
                                    <Typography align="center" variant="subtitle2" gutterBottom style={{ marginTop: "-1em" }}>
                                        Clicking the room, will release it back to available.

                                    </Typography>
                                    <Button color="primary" onClick={unRoom(room.CIOID)} >
                                        <Typography align="center" variant="h4" gutterBottom>
                                            {room.RoomName}
                                        </Typography>
                                    </Button>


                                </Paper>

                            </Grid>
                        </div>
                    ))}

                </Grid>
            </Grid>
            <AuthenticatedTemplate>

            <Grid item>

                <Grid container alignItems="center" justify="center" style={{ height: "30em", marginTop: matchesSM ? "6em" : "8em" }}>
                    <Card className={classes.cityCard}>
                        {cities.sort((a, b) => a.CityName.localeCompare(b.CityName)).map((city) => (

                            <CardContent key={city.CityID}>
                                <Grid container direction="column" style={{ textAlign: "center" }} >
                                    <Grid item>
                                        <Typography variant="h3" gutterBottom>
                                            {city.CityName}
                                        </Typography>
                                    </Grid>
                                    {locations.filter((location) => location.CityName === city.CityName).map((location) => (
                                        <Grid
                                            container
                                            direction="row"

                                            key={location.LocationID}
                                        >
                                            <Grid item style={{ marginTop: "2em" }}>
                                                <Tooltip title={location.Description} placement="right">
                                                    <Typography variant="h4" gutterBottom>
                                                        {location.LocationName}
                                                    </Typography>
                                                </Tooltip>
                                            </Grid>
                                            <Grid container direction="row" spacing={4} style={{ marginTop: "-2em" }}>
                                                {room.filter((room, index) => room.LocationID === location.LocationID).map((room, index) => (

                                                    <Grid item key={index}>


                                                        <Button
                                                            variant="outlined"
                                                            className={classes.button}
                                                            onClick={openRoom(room)}
                                                            disabled={(room.IsOpen === false ? true : false)}
                                                        >
                                                            {room.RoomName}
                                                        </Button>

                                                        <div>Floor: {room.FloorID}</div>
                                                        <div>{room.Description}</div>
                                                        {(room.DoorCode === "" || room.DoorCode === "NULL" ? "":<div>Door Code: {room.DoorCode}</div>)}
                                                        {(room.IsOpen === true ? "" : room.IsOpen === false && getTimeRemaining(room.ReleaseDateTime, room.CurrentTime).totmin > "00" ? <div style={{ color: "red" }}>{getTimeRemaining(room.ReleaseDateTime, room.CurrentTime).totmin} minutes until open.</div> : <div style={{ color: "red" }}>Only seconds until open.</div>)}


                                                        <Dialog open={open}
                                                            onClose={() => setOpen(false)}
                                                        >
                                                            <DialogTitle id="form-dialog-title">Room Information</DialogTitle>
                                                            <DialogContent>
                                                                <Grid container direction="column">
                                                                    <Grid item>
                                                                        <TextField label="Room Name" id="{room.RoomID}" value={currRoom.RoomName} InputProps={{ readOnly: true, }} />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <TextField label="Floor" id="{currRoom.FloorID}" value={currRoom.FloorID} InputProps={{ readOnly: true, }} />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <TextField label="Description" id="{currRoom.Description}" value={currRoom.Description} InputProps={{ readOnly: true, }} />
                                                                    </Grid>
                                                                </Grid>

                                                            </DialogContent>
                                                            <DialogActions>
                                                                <Button onClick={() => setOpen(false)} color="primary">
                                                                    Cancel
                                                                </Button>
                                                                <Button onClick={disRoom(false, currRoom.LocationID, currRoom.RoomID, userCookie, currRoom.RoomName, currRoom.Open)} color="primary">
                                                                    Check Out
                                                                </Button>
                                                            </DialogActions>
                                                        </Dialog>

                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    ))}

                                </Grid>
                                <Divider />
                            </CardContent>

                        ))}

                    </Card>

                </Grid>


            </Grid>

            </AuthenticatedTemplate>
            <Grid item>
                <Grid container justify='center'>
                    {isAuthenticated ? "" : <SignInButton />}
                </Grid>
            </Grid>
        </Grid>
    )
}