import {createMuiTheme} from '@material-ui/core/styles';

const mhSlate = "#002F3B";
const mhCream = "#EBA921";
const arcGrey = "#868686";

export default createMuiTheme({
    palette: {
        common:{
        slate: `${mhSlate}`,
        cream: `${mhCream}`
    },
    primary: {
        main: mhSlate
    },
    secondary: {
        main: mhCream
    }
},
typography: {
    tab: {
      fontFamily: "Raleway",
      textTransform: "none",
      fontWeight: 700,
      color: "white",
      fontSize: "1rem"
    },
    estimate: {
      fontFamily: "Pacifico",
      fontSize: "1rem",
      textTransform: "none",
      color: "white"
    },
    h2: {
      fontFamily: "Raleway",
      fontWeight: 700,
      fontSize: "2.5rem",
      color: mhSlate,
      lineHeight: 1.5
    },
    h3: {
      fontFamily: "Pacifico",
      fontSize: "3rem",
      color: mhSlate
    },
    h4: {
      fontFamily: "Raleway",
      fontSize: "1.75rem",
      color: mhSlate,
      fontWeight: 700
    },
    h5: {
      fontFamily: "Pacifico",
      fontSize: "2.5rem",
      color: mhSlate
    },
    h6: {
      fontWeight: 500,
      fontFamily: "Raleway",
      color: mhSlate
    },
    subtitle1: {
      fontSize: "1.25rem",
      fontWeight: 300,
      color: arcGrey
    },
    subtitle2: {
      color: arcGrey,
      fontWeight: 300,
      fontSize: ".75rem"
    },
    body1: {
      fontSize: "1.25rem",
      color: arcGrey,
      fontWeight: 300
    },
    caption: {
      fontSize: "1rem",
      fontWeight: 300,
      color: arcGrey
    },
    learnButton: {
      borderColor: mhSlate,
      borderWidth: 2,
      textTransform: "none",
      color: mhSlate,
      borderRadius: 50,
      fontFamily: "Roboto",
      fontWeight: "bold"
    }
  },
  overrides: {
    MuiInputLabel: {
      root: {
        color: mhSlate,
        fontSize: "1rem"
      }
    },
    MuiInput: {
      root: {
        color: arcGrey,
        fontWeight: 300
      },
      underline: {
        "&:before": {
          borderBottom: `2px solid ${mhSlate}`
        },
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottom: `2px solid ${mhSlate}`
        }
      }
    }
  }
});